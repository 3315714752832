.apps-table {
    padding: 1.5em;
    max-width: 1280px;
    margin: 0 auto;

    article.media.app {
        cursor: pointer;

        .media-left {
            color: $pale;
        }
        
        .media-content {
            p {
                color: $pale;
                
                strong, small {
                    padding: 1em;
                    color: $pale;
                }

                strong {
                    font-family: "MandatoryPlaything";
                    font-size: large;
                }

                small {
                    font-family: "GoodTimes";
                }
            }
        }
    }
    & > button {
        margin-top: 1em;
    }
}