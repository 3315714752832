div.users-page {
    max-width: 1280px;
    padding: 1.5em;
    margin: 0 auto;
}

.user-search-form {
    display: flex;
    flex-direction: row;
    width: 100%;

    div.field:first-child {
        flex-grow: 1;
    }
}

div.users-table {
    display: grid;
    grid-template-columns: repeat(6, max-content);
    color: $pale;
    gap: 1rem;
    align-items: baseline;
    font-weight: 400;
    justify-content: space-between;

    .row-divider {
        grid-column: 1/-1;
        border-bottom: 1px solid $pale;
        &:last-of-type {
            border-bottom: none;
        }
    }
    .header-entry {
        font-size: x-large;
        font-weight: 700;
    }
    figure.image {
        padding-left: 0.5em;
        img {
            border-radius: 5em;
        }
    }
    span.icon {
        color:$pale;
        width: 100%;
        justify-content: end;
    }
    strong {
        font-size:large;
        color: $pale;
    }

    small {
        text-align: center;
        font-weight: 400;
        font-size: medium;
    }
    .editable, .clickable {
        cursor:pointer;
    }
    button {
        grid-column: 1/-1;
    }
}

.user-info {
    display: grid;
    grid-template-columns: 64px 1fr;

    figure.media-left {
        color:$dark;
        img{
            border-radius: 5em;
        }
    }

    div.identities {
        div{ padding-left: 1em; }
    }

    div.user-details {
        padding-left: 1em;
        p {
            small { padding-left: 1em; }
        }
    }
}

div.user-suggestion {
    display:flex;
    flex-direction:row;
    align-items: end;

    strong {
        padding: 0 0.5em;
    }

    small {
        padding-bottom: 0.1em;
    }
}

form.user-form {

    margin-top: 1em;
    padding:1em;
    border-radius: 1em;
    border: 1px solid $pale;

    h3.is-title {
        font-size: large;
        font-weight: 600;
        text-decoration: underline;
        margin-bottom: 0.5rem;
    }

    & > div.field {
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;

        label.label { 
            padding-left: 1em;
            align-self:center;
            margin-bottom: 0;
        }
    }

    .claim-field {
        &.role {
            background-color: $pale; 
        }
        &.user {
            background-color: $tint;
            &.removed {
                background-color: $danger-tint;
            }
        }
    }
}