.collapsible {
    .collapse-header {
        &:before {
            padding-right: 1rem;
        }
    }
    .collapse-header {
        border-bottom: 1px solid rgba(0,0,0,0);
        transition: border-bottom 250ms ease-out;
    }
    &.active {
        & > .collapse-header {
            border-bottom: 1px solid $dark;
        }
        & > .collapse-body {
            margin-left: 2rem;
            padding: 0.5rem 0;
        }
    }
}