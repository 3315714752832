#discord-login {
    background-color: #5865f2;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    min-width: 200px;
    max-width:400px;
    font-size: 14px;
    font-weight: 500;
    font-family: Roboto,sans-serif;
    line-height: 16px;
    padding: 2px 16px;
    border: 0;
    border-radius: 3px;
    box-sizing: border-box;
    cursor: pointer;
    transition: background-color .15s ease,color .15s ease;
}

.login-page {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    height: 100vh;
}

.modal.login {
    .card-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
}