/*import our styles*/
$primary: #A2CADE;
$dark: #011;
$pale: #dadada;
$tint: #d1e5e1;
$wash: #596157;
$danger-tint: #f4c4d3;

.image.is-72x72 {
  height: 72px;
  width: 72px;
}

@import "./navbar.scss";
@import "./role.scss";
@import "./user.scss";
@import "./claim.scss";
@import "./applist.scss";
@import "./permissionset.scss";
@import "./editapp.scss";
@import "./apps.scss";
@import "./login.scss";
@import "./collapsible.scss";