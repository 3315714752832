aside.app-navigator {
    display:none;
    position:fixed;
    left:0;
    top:0;
    transform: translateX(-100%);

    height:100vh;
    font-size:1.5em;
    padding: 0.85em;
    z-index:1000;
    background-color: #011;

    -webkit-box-shadow: 5px 0px 8px 1px #000; 
    box-shadow: 5px 0px 8px 1px #000;

    cursor: pointer;

    &.enabled {
        display: block;
        transition: transform 0.2s ease-out;
    }

    &.active {
        transform:inherit;
    }

    .menu-label {
        margin-bottom:0;
        font-family: 'MandatoryPlaything';
        font-size: 1.5em;
    }

    ul.menu-list {
        li {
            font-family: "GoodTimes";
            &.is-active {
                background-color:$pale;
                color:#000;
            }

            a i {
                padding-right: 0.5em;
            }
        }
    }
}

.navbar .navbar-brand .navbar-item svg.active {
    cursor:pointer;
}