.claim-field {
    display:grid;
    grid-template-columns: 1fr 2em;
    align-items:center;
    margin-bottom: 0.5em;
    border-radius: 0.5em;
    padding: 0.5em;

    div.field {
        margin-bottom:0;
        display:grid;
        grid-template-columns: 1fr 1fr;

        label.label {
            margin-bottom: 0;
            padding-left: 0.5em;
        }

        div.control {
            justify-self: center;
        }

        label.checkbox {
            display:grid;
            grid-template-columns: 1fr 1fr;
            grid-column: 1 / span 2;
            align-items: center;
            width: 100%;
    
            label {
                margin-bottom: 0;
                padding-left: 0.5em;
            }

            input[type=checkbox] {
                justify-self:center;
            }
        }
    }

    
}