.permission-set {
    .permission-group {       
        & > div:first-child {
            padding:0.5em;
            border:1px solid $pale;
            border-radius:1em;
        }
        
        &:not(:first-of-type) {
            .permission-entry {
                .field {
                    label.label {
                        display:none;
                    }
                }
            }
        }

        .permission-entry {
            display:grid;
            grid-template-columns: 1fr 1fr 1fr auto;
    
            &:not(:first-child) {
                .field {
                    label.label {
                        display:none;
                    }
                }
            }

            .field.inclusive {
                display: flex;
                flex-direction: column;

                :not(:first-child) {
                    margin-top: 1em;
                }
            }

            .checkbox {
                flex-direction:column;
                height: 100%;

                input {
                    flex-grow: 1;
                }
            }
        }

        .field.exclusive {
            text-align: center;
            padding-top: 0.5em;
        }
    }
}