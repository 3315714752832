nav.navbar {
    background-color:$dark;
    color:$pale;
    position: sticky;
    top: 0;

    p.title {
        color:$pale;
        position: relative;
        top: -0.3em;
        font-size:xxx-large;
        font-family: 'MandatoryPlaything'
    }

    div.navbar-menu {
        color:$pale;
        position:absolute;
        bottom:0;
        left:5em;
        font-size:large;
        font-family:'GoodTimes';
    }

    a.navbar-item {
        &:hover{
            background-color:inherit;
            color: $primary;
        }
        
        &.active {
            color: $pale;
            text-decoration: underline;
        }
    }

    div.navbar-end .navbar-item {
        align-items:center;
        margin:16px;

        figure {
            cursor:pointer;
        }

        .navbar-dropdown.is-boxed {
            font-size: large;
            font-weight: 600;
            font-family: "MandatoryPlaything";
            .navbar-item {
                color: $dark;
                margin: 0.5rem;
                cursor: pointer;
            }
        }
    }
}