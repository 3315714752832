.app-form {
    .icon-select {
        position:relative;
        padding-bottom: 0.5em;

        i.fas {
            position:absolute;
            right: 0.75em;
            bottom: 1.85em;
        }
    }
}