div.roles-table {
    padding: 1.5em;
    max-width: 1280px;
    margin: 0 auto;

    div.role-container {
        font-size: x-large;
        font-family: 'Neuropolitical';
        color: $pale;
        cursor: pointer;
        padding:0.5em;
        display: flex;
        flex-direction: row;
        gap: 1rem;

        i.fas {
            position: relative;
            top: -0.1em;
        }

        strong {
            color: $pale;
        }

        small {
            font-size: large;
            color: $wash;
            text-decoration: underline;
        }

        button {
            margin-left: auto;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid $pale;
        }
    }
}

form.role-form {
    .form-control-row {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        .field {
            flex-grow: 1;
            label.b-checkbox {
                flex-direction: column;
                margin: 0;
                width: 100%;
                height: 100%;
                label.label {
                    padding: 0;
                    text-align: center;
                }
                input {
                    height: 100%;
                    min-height: 2.5rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }

    .claim-field {
        &.role {
            background-color: $tint;
            &.removed {
                background-color: $danger-tint;
            }
        }
    }
}